import React, { useRef, useEffect } from 'react';

const projects = [
  {
    title: "Quotivation Station",
    tech: "React, JavaScript, Express, MongoDB Atlas, Node.js, Docker",
    description: "A user-friendly web app with 1000+ users, lauded for innovative image generation and quote sharing features. Containerized with Docker Compose for scalable architecture. Implemented compound indexing and LRU caching for improved performance.",
    link: "www.siddhardhakurra.com"
  },
  {
    title: "TimeVault App",
    tech: "Django, Python3, web3, Truffle, Ethereum Blockchain, SQLite3",
    description: "A blockchain-based time capsule app with Truffle smart contracts on Ethereum, ensuring tamper-proof security and integrity. Developed an intuitive UI with Django and Bootstrap, boosting user engagement by 25%."
  },
  {
    title: "Inventory System",
    tech: "Java, J2EE, JPA, JAX-RS, CDI, JSP, JMS, WebSockets, Threads",
    description: "Introduced lazy loading, Trie-based indexing, real-time chat via WebSockets, and thread pooling for improved performance and efficiency in inventory management."
  },
  {
    title: "Real-Time Collaborative Text Editor",
    tech: "C++, WebSocket, CRDT Algorithm, Git, STL, Qt",
    description: "Built a real-time collaborative text editor using C++, Qt, and WebSocket protocol with CRDT algorithm for conflict-free concurrent editing. Optimized performance and UI with STL containers, algorithms, modern C++ features, and Qt GUI widgets."
  }
];

const ProjectCard = ({ project }) => (
  <div className="project-card">
    <div className="project-card-content">
      <h3 className="project-title">{project.title}</h3>
      <p className="project-tech">{project.tech}</p>
      <p className="project-description">{project.description}</p>
      {project.link && (
        <a href={project.link} target="_blank" rel="noopener noreferrer" className="project-link">
          View Project
        </a>
      )}
    </div>
  </div>
);

const ProjectsSection = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const handleWheel = (e) => {
      if (container) {
        if (e.deltaY !== 0) {
          e.preventDefault();
          container.scrollLeft += e.deltaY;
        }
      }
    };

    if (container) {
      container.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  return (
    <section id="projects" className="projects-section">
      <div className="projects-inner-container">
        <h2 className="section-title">My Projects</h2>
        <div className="projects-container" ref={containerRef}>
          {projects.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;